import React from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import Image from "gatsby-image"
import SEO from "../components/SEO"
import { useLanguage } from "../contexts/LanguageContext"

const Info = ({
  data: {
    file: {
      childImageSharp: { fluid },
    },
  },
}) => {
  const { selectedLanguage } = useLanguage() // Use the useLanguage hook
  return (
    <Layout>
      <SEO title="Osnovni podaci" />
      {selectedLanguage === "EN" ? (
        <div className="img-wrapper img-wrapper-about">
          <Image Tag="div" fluid={fluid} className=" bcg bcg-about  " />
          <h2 className="about-header visibility-filter ">Basic information</h2>
          <h2 className="about-header mobile-visible">Basic information</h2>
        </div>
      ) : (
        <div className="img-wrapper img-wrapper-about">
          <Image Tag="div" fluid={fluid} className=" bcg bcg-about  " />
          <h2 className="about-header visibility-filter ">Osnovni podaci</h2>
          <h2 className="about-header mobile-visible">Osnovni podaci</h2>
        </div>
      )}

      {selectedLanguage === "EN" ? (
        <div className="about-content mobile-visible ">
          <h4 className="rules-mobile-header">Company: OSIJEK-KOTEKS d.d.</h4>
          <h4 className="rules-mobile-header">OIB: 44610694500</h4>
          <h4 className="rules-mobile-header">
            Company Registration Number (MBS): 030010020
          </h4>
          <h4 className="rules-mobile-header">
            Registration Court: Commercial Court in Osijek
          </h4>
          <h4 className="rules-mobile-header">
            Share Capital: 23.812.880,00 euros, fully paid
          </h4>
          <h4 className="rules-mobile-header">
            Number of shares: 1.831.760, each with a nominal value of 13,00
            euros{" "}
          </h4>
          <h4 className="rules-mobile-header">
            IBAN: HR38 248 10001123001112 SWIFT: KREZHR2X - Agram banka d.d.
          </h4>
          <h4 className="rules-mobile-header">
            Zoran Škorić, President of the Management Board • Marko Tadić, Board
            Member • Ivan Oroz, Board Member • Dr. Alexander Schoeller, Chairman
            of the Supervisory Board
          </h4>
          <h4 className="rules-mobile-header">
            Management Address: Šamačka 11, 31000 Osijek, Croatia
          </h4>
          <h4 className="rules-mobile-header">
            Contact: info@osijek-koteks.hr • phone: +385 (0)31 22 77 00
          </h4>
        </div>
      ) : (
        <div className="about-content mobile-visible ">
          <h4 className="rules-mobile-header">Tvrtka: OSIJEK-KOTEKS d.d.</h4>
          <h4 className="rules-mobile-header">OIB: 44610694500</h4>
          <h4 className="rules-mobile-header">
            Matični broj subjekta (MBS): 030010020
          </h4>
          <h4 className="rules-mobile-header">
            Sud upisa: Trgovački sud u Osijeku
          </h4>
          <h4 className="rules-mobile-header">
            Temeljni kapital društva: 23.812.880,00 eura, uplaćen u cijelosti
          </h4>
          <h4 className="rules-mobile-header">
            Broj dionica: 1.831.760, svaka u nominalnom iznosu od 13,00 eura{" "}
          </h4>
          <h4 className="rules-mobile-header">
            IBAN: HR38 248 10001123001112 SWIFT: KREZHR2X - Agram banka d.d.
          </h4>
          <h4 className="rules-mobile-header">
            Zoran Škorić, predsjednik Uprave • Marko Tadić, član Uprave • Ivan
            Oroz, član Uprave • Dr. Alexander Schoeller, predsjednik Nadzornog
            odbora
          </h4>
          <h4 className="rules-mobile-header">
            Adresa uprave: Šamačka 11, 31000 Osijek, Hrvatska
          </h4>
          <h4 className="rules-mobile-header">
            Kontakt: info@osijek-koteks.hr • tel: +385 (0)31 22 77 00
          </h4>
        </div>
      )}
      {selectedLanguage === "EN" ? (
        <div className="about-content visibility-filter">
          <h4 className="about-paragraph">
            <h4 className="rules-header">Company: OSIJEK-KOTEKS d.d. </h4>
            <h4 className="rules-header">OIB: 44610694500</h4>
            <h4 className="rules-header">
              Company Registration Number (MBS): 030010020
            </h4>
            <h4 className="rules-header">
              Registration Court: Commercial Court in Osijek
            </h4>
            <h4 className="rules-header">
              Share Capital: 23,812,880.00 euros, fully paid
            </h4>
            <h4 className="rules-header">
              Number of shares: 1,831,760, each with a nominal value of 13.00
              euros{" "}
            </h4>
            <h4 className="rules-header">
              IBAN: HR38 248 10001123001112 SWIFT: KREZHR2X - Agram banka d.d.
            </h4>
            <h4 className="rules-header">
              Zoran Škorić, President of the Management Board • Marko Tadić,
              Board Member • Ivan Oroz, Board Member • Dr. Alexander Schoeller,
              Chairman of the Supervisory Board
            </h4>
            <h4 className="rules-header">
              Management Address: Šamačka 11, 31000 Osijek, Croatia
            </h4>
            <h4 className="rules-header">
              Contact: info@osijek-koteks.hr • phone: +385 (0)31 22 77 00
            </h4>
          </h4>
        </div>
      ) : (
        <div className="about-content visibility-filter">
          <h4 className="about-paragraph">
            <h4 className="rules-header">Tvrtka: OSIJEK-KOTEKS d.d. </h4>
            <h4 className="rules-header">OIB: 44610694500</h4>
            <h4 className="rules-header">
              Matični broj subjekta (MBS): 030010020
            </h4>
            <h4 className="rules-header">Sud upisa: Trgovački sud u Osijeku</h4>
            <h4 className="rules-header">
              Temeljni kapital društva: 23.812.880,00 eura, uplaćen u cijelosti
            </h4>
            <h4 className="rules-header">
              Broj dionica: 1.831.760, svaka u nominalnom iznosu od 13,00 eura{" "}
            </h4>
            <h4 className="rules-header">
              IBAN: HR38 248 10001123001112 SWIFT: KREZHR2X - Agram banka d.d.
            </h4>
            <h4 className="rules-header">
              Zoran Škorić, predsjednik Uprave • Marko Tadić, član Uprave • Ivan
              Oroz, član Uprave • Dr. Alexander Schoeller, predsjednik Nadzornog
              odbora
            </h4>
            <h4 className="rules-header">
              Adresa uprave: Šamačka 11, 31000 Osijek, Hrvatska
            </h4>
            <h4 className="rules-header">
              Kontakt: info@osijek-koteks.hr • tel: +385 (0)31 22 77 00
            </h4>
          </h4>
        </div>
      )}
    </Layout>
  )
}
export const query = graphql`
  {
    file(relativePath: { eq: "pexels-tima-miroshnichenko-6474345-min.jpg" }) {
      childImageSharp {
        fluid(
          sizes: "(max-width: 300px) calc(100vw - 25px), (max-width: 600px) calc(100vw - 15px), 1300px"
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default Info
